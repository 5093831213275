import type { NextPage } from "next";
import { useSession } from "next-auth/react";
import Login from "../components/Login";
import MetaComponent from "../components/Meta";

const Home: NextPage = () => {
  const { data: session } = useSession();

  if (session) {
    if (session.token?.tokenData?.role === "notifications") {
      window.location.replace("/notifications/group?page=1");
    } else {
      window.location.replace("/dashboard");
    }
  }

  return (
    <>
      <MetaComponent title="Login" />
      {!session && <Login />}
    </>
  );
};

export default Home;
