import type { NextPage } from "next";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { ImageComponent } from "./ImageComponent";
import { BRAND_IMAGE } from "@/constants";

const Login: NextPage = () => {
  const router = useRouter();

  return (
    <div className="wrapper wrapper--no-sidebar">
      <div className="vh-100 bg-light d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="col-10">
            <div>
              <div className="text-center">
                <ImageComponent
                  src={BRAND_IMAGE}
                  alt="logo"
                  width={50}
                  height={50}
                  className="mix-blend"
                />
              </div>

              <div className="p-4 bg-white shadow mt-3 text-center rounded-lg">
                <p className="mt-2">Login to your account to continue.</p>

                <span
                  onPointerDown={() =>
                    signIn("google", {
                      callbackUrl:
                        (router.query.callbackUrl as string) ??
                        `${window.location.origin}/dashboard`,
                    })
                  }
                  className="btn text-white text-white rounded mb-3"
                  style={{ background: "#5086EC" }}
                >
                  <span className="mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 18 18"
                      className="bg-white p-1 rounded"
                    >
                      <path
                        className="a-google"
                        d="M139.366,108.6a7.565,7.565,0,0,0-.194-1.84H130.55v3.34h5.061a4.385,4.385,0,0,1-1.878,2.92l-.017.112,2.726,2.07.189.018a8.712,8.712,0,0,0,2.735-6.62"
                        transform="translate(-121.366 -99.401)"
                      />
                      <path
                        className="b-google"
                        d="M22.129,163.59a8.858,8.858,0,0,0,6.082-2.18l-2.9-2.2a5.679,5.679,0,0,1-8.408-2.84l-.108.009-2.835,2.15-.037.1a9.2,9.2,0,0,0,8.2,4.96"
                        transform="translate(-12.945 -145.59)"
                      />
                      <path
                        className="c-google"
                        d="M3.959,77.176a5.441,5.441,0,0,1-.306-1.78,5.716,5.716,0,0,1,.3-1.78L3.944,73.5l-2.87-2.185-.094.044a8.821,8.821,0,0,0,0,8.08l2.98-2.26"
                        transform="translate(0 -66.396)"
                      />
                      <path
                        className="d-google"
                        d="M22.129,3.48A5.145,5.145,0,0,1,25.68,4.82l2.592-2.48A8.922,8.922,0,0,0,22.129,0a9.2,9.2,0,0,0-8.2,4.96l2.969,2.26a5.541,5.541,0,0,1,5.235-3.74"
                        transform="translate(-12.945)"
                      />
                    </svg>
                  </span>{" "}
                  Sign in with Google
                </span>
                <span
                  onPointerDown={() =>
                    signIn("azure-ad", {
                      callbackUrl:
                        (router.query.callbackUrl as string) ??
                        `${window.location.origin}/dashboard`,
                    })
                  }
                  className="btn-dark btn text-white text-white rounded mb-3"
                >
                  <span className="mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 19 19"
                    >
                      <rect className="a-microsoft" width="9" height="9" />
                      <rect
                        className="b-microsoft"
                        width="9"
                        height="9"
                        transform="translate(0 10)"
                      />
                      <rect
                        className="c-microsoft"
                        width="9"
                        height="9"
                        transform="translate(10)"
                      />
                      <rect
                        className="d-microsoft"
                        width="9"
                        height="9"
                        transform="translate(10 10)"
                      />
                    </svg>
                  </span>{" "}
                  Sign in with Microsoft
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
